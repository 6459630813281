/* global window */
import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import HeroSearch from '../../HeroSearch/HeroSearch';
import { connect } from 'react-redux';
import { setOrigin } from 'Actions/track';
import ActionButtons from './../components/ActionButtons/ActionButtons';
import MultiLanguagePicker from './../components/MultiLanguagePicker/MultiLanguagePicker';
import withTrack from 'HOCs/withTrack/withTrack';
import css from './DefaultHeader.desktop.scss';
import classNames from 'classnames';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import { ICONS, ICON_COLORS } from 'Constants/items';
import withRouter from 'HOCs/withRouter';
import Button from 'Components/Button/Button';
import { FormattedMessage as Translation } from 'react-intl';

export const DefaultHeaderContent = ({
    renderLogo,
    searchTerm,
    params,
    isOnListing,
    hideSearchBar,
    loginCallback,
    monetTrackingParams,
    hideActionButtons,
    showHelpButton,
    config,
    isMeetingPage,
    showCompareIcon,
    hideMultiLang,
    router,
    track,
    trackerOrigins,
    setOriginTrack,
    flowStep,
    experimentVariant
}) => {
    const multiLangFlag = config.get('enableMultiLang');
    const paramProps = useMemo(() => {
        return params;
    }, [params]);

    const trackActionButtonClick = useCallback(buttonName => {
        const origin = trackerOrigins.HEADER;
        let eventName = '';

        setOriginTrack(origin);
        switch (buttonName) {
            case 'chat':
                eventName = 'chat_inbox';
                break;
            case 'sell':
                eventName = 'posting_tap_post';
                break;
        }

        track(eventName, {
            origin,
            select_from: 'header'
        });
    }, [setOriginTrack, track, trackerOrigins.HEADER]);

    function onClose() {
        router.goBack();
    }

    function handleHelpClick() {
        track('monet_help_button', monetTrackingParams);
        if (document.getElementById('help')) {
            window.scroll({
                behavior: 'smooth',
                top: document.getElementById('help').offsetTop - 10 // eslint-disable-line no-magic-numbers
            });
        }
    }

    return (
        <React.Fragment>
            <div className={ css.headerFA }>
                {experimentVariant && <NewIconButton
                    onClick={ onClose }
                    icon={ ICONS.BACK }
                    color={ ICON_COLORS.PRIMARY_BASE }
                    size={ 20 }
                />}
                { !experimentVariant && renderLogo() }
            </div>
            <div className={ classNames(css.search) }>
                {
                    !hideSearchBar && <HeroSearch
                        size="small"
                        searchTerm={ searchTerm }
                        params={ paramProps }
                        isOnListing={ isOnListing }
                        isMeetingPage={ isMeetingPage }
                    />
                }
            </div>
            { !hideMultiLang && multiLangFlag && <MultiLanguagePicker /> }
            { !hideActionButtons
                && <ActionButtons
                    className={ css.actionButtons }
                    loginCallback={ loginCallback }
                    onClickTrack={ trackActionButtonClick }
                    showCompareIcon={ showCompareIcon }
                    flowStep={ flowStep }
                />
            }
            {!!showHelpButton
            && <Button
                key="help"
                type="tertiaryBtn"
                data-aut-id="btnLogin"
                onClick={ handleHelpClick }
                className={ classNames(css.buttons) }
            >
                <Translation id="help" />
            </Button>}
        </React.Fragment>
    );
};

DefaultHeaderContent.propTypes = {
    renderLogo: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    isOnListing: PropTypes.bool,
    monetTrackingParams: PropTypes.object,
    params: PropTypes.object,
    hideSearchBar: PropTypes.bool,
    hideActionButtons: PropTypes.bool,
    showHelpButton: PropTypes.bool,
    router: PropTypes.shape({
        goBack: PropTypes.func
    }),
    experimentVariant: PropTypes.bool,
    isMeetingPage: PropTypes.bool,
    showCompareIcon: PropTypes.bool,
    loginCallback: PropTypes.func,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    hideMultiLang: PropTypes.bool,
    track: PropTypes.func.isRequired,
    trackerOrigins: PropTypes.object.isRequired,
    setOriginTrack: PropTypes.func.isRequired,
    flowStep: PropTypes.string
};

DefaultHeaderContent.defaultProps = {
    showUnreadChat: false,
    hideSearchBar: false,
    isMeetingPage: false,
    monetTrackingParams: {},
    showCompareIcon: false,
    hideActionButtons: false,
    loginCallback: () => {},
    hideMultiLang: false
};

export const mapStateToProps = ({ toggleUnreadChats }) => ({
    showUnreadChat: toggleUnreadChats
});

export const mapDispatchToProps = dispatch => ({
    setOriginTrack: origin => dispatch(setOrigin(origin))
});

export default compose(
    withTrack,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(DefaultHeaderContent);
